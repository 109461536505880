import { Box, Button, Stack } from "@mui/material";
import { useContactDrawer } from "components/Contact/useContactDrawer";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toggle_drawer } from "state/actions";
import { getFocus, useAtom, useGet } from "state/jotai";
import Unit from "./Unit.model";
import { active_project, params_query, previousUrl } from "state/store.global";
import { _project } from "state/store.projects";
import { useLocation } from 'react-router-dom';
import { animateScroll } from "react-scroll";
import useUnitPage from "./useUnitPage";
import { useBreak } from "hooks/useBreak";

function ReservationButton({ unit }) {
  const location       = useLocation();
  const here           = useLocation().pathname;
  const atUnit         = here.includes('unit');
  const [refContainer] = useUnitPage();
  const isDesktop      = useBreak('md_up')

  const [, setPreviousUrl] = useAtom(previousUrl);
  const { open }           = useContactDrawer();

  const navigateTo = useNavigate();
  const params     = useGet(params_query);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];

  const {
    i18n: { language },
  } = useTranslation();
  const [, toggleDrawer] = useAtom(toggle_drawer);

  const [selectedButton, setSelectedButton] = useState(atUnit? "contactUs" : "readMore");

  const handleMore = () => {
    setPreviousUrl(location.pathname);
    navigateTo(`/${language}/unit/${unit.getId()}${params.length ? params : ''}`);
    toggleDrawer();
    setSelectedButton("readMore");
  };

  const handleClick = () => {
      // Commented this code because client wants to open sign up page
    const targetElement = document.getElementById("unit_page_contact_form");

    if(isDesktop) {
      refContainer.scrollTo(0, targetElement.offsetTop)
    }else {
    if (targetElement) {
      const offset = targetElement.offsetTop;
      animateScroll.scrollTo(offset, {
        container: refContainer,
      });
    }
    }
  };
  
  const handleContact = () => {
      // Commented this code because client wants to open sign up page
    open(unit);
    setSelectedButton("contactUs");
  };
  const handleBookView = () => {
    setSelectedButton("bookViewing");
  };
  const handleSignUp = () => {
    setSelectedButton("signUp");
  };

  return (
    <Box   sx      = {{ padding: atUnit ? "" : "24px 32px" }}>
    <Stack spacing = {2}>
        {!atUnit && 
        <Button
          variant = {selectedButton === "readMore" ? "contained" : "outlined"}
          size    = "large"
          sx      = {{
            borderRadius: '6px',
            color       : selectedButton === "readMore" ? 'white': 'black',
            border      : selectedButton === "readMore" ? 'none' : '1px solid #D1D1D1',
            padding     : "11px 40px",
            '&:hover'   : {
              border    : selectedButton  === "readMore" ? 'none' : '1px solid #D1D1D1',
              background : selectedButton === "readMore" ? '#0000000' : '#EFEFEF'
            },
          }}
          onClick = {handleMore}
        >
          <div className = "paragraph1-regular" style = {{fontWeight : 600}}>
            {
              buttonsLabel?.filter(
                (btn) => btn?.UILabelProperty === "Læsmereomenhed"
              )[0]?.Label
            }
          </div>
        </Button>}
        {/* <Button
          variant = {selectedButton === "bookViewing" ? "contained" : "outlined"}
          size    = "large"
          sx      = {{
            borderRadius: '10px',
            color       : selectedButton === "bookViewing" ? 'white': 'black',
            border      : selectedButton === "bookViewing" ? 'none' : '1px solid #D1D1D1',
            '&:hover'   : {
              border: selectedButton === "bookViewing" ? 'none' : '1px solid black',
            },
          }}
          onClick = {handleBookView}
        >
          Book viewing
        </Button> */}
        {/* <Button
          variant = {selectedButton === "signUp" ? "contained" : "outlined"}
          size    = "large"
          sx      = {{
            borderRadius: '10px',
            color       : selectedButton === "signUp" ? 'white': 'black',
            border      : selectedButton === "signUp" ? 'none' : '1px solid #D1D1D1',
            '&:hover'   : {
              border: selectedButton === "signUp" ? 'none' : '1px solid #D1D1D1',
            },
          }}
          onClick = {handleSignUp}
        >
          Sign up and receive the latest news
        </Button> */}
        <Button
          variant = {selectedButton === "contactUs" ? "contained" : "outlined"}
          size    = "large"
          sx      = {{
            borderRadius: '10px',
            color       : atUnit ? clr?.buttons_text             : clr?.primary_text,
            border      : selectedButton === "contactUs" ? 'none': '1px solid #D1D1D1',
            padding     : "11px 40px",
            '&:hover'   : {
              border    : selectedButton  === "contactUs" ? 'none' : '1px solid #D1D1D1',
              background : selectedButton === "contactUs" ? '#0000000' : '#EFEFEF'
            },
          }}          
          onClick = {atUnit ? handleClick : handleContact}
        >
          <span className = "paragraph1-regular" style = {{fontWeight : 600, color: selectedButton === "contactUs" ? "#fff" : "#000" }}>
        {
          buttonsLabel?.filter(
            (btn) => btn?.UILabelProperty === "kontaktOs"
          )[0]?.Label
        }
          </span>
        </Button>
      </Stack>
    </Box>
  );
}

export default ReservationButton;
