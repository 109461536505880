import { Isometry }    from 'components/Isometry/Isometry'
import { IsometrySub } from 'components/Isometry/Subisometry'
import { useBreak } from 'hooks/useBreak';
import { useAtom } from 'jotai';
import { useParams }   from 'react-router-dom'
import {  toggle_drawer } from 'state/actions';
import { vis_desktop_nav, vis_detail, vis_filter } from 'state/store.global';

export const ViewIsometry = () => {
  const isDesktop    = useBreak("md_up");
  const detailDrawer = useAtom(vis_detail)[0];
  const toggle = useAtom(toggle_drawer)[1];
  const sub = useParams('sub').sub
  
  const [, setShowFilter] = useAtom(vis_filter);
  const [, setShowMenu] = useAtom(vis_desktop_nav);

  return (
    <>
      <div style={{border:'0px solid gray',height: isDesktop ? 'calc(100vh - 76px)' : 'calc(100vh - 149px)',position:'relative'}} onClick={()=> {
        
        detailDrawer && toggle("detail")
        setShowFilter(false);
        setShowMenu(false);
    
    }}>
        {/* { !sub && <Isometry/> }
        { sub  && <IsometrySub sub={sub}/> } */}
         <Isometry/>
      </div>
    </>
  )
}