import { useEffect, useRef, useState }                 from 'react'
import { motion }                   from 'framer-motion'
import { AnimatePresence }          from 'framer-motion'
import { Menu, Tile, Split, Text }  from './Nav.Desktop.Foldout.style'
import { Icon }                     from 'assets/Icons_'
import { Link }                     from 'react-router-dom'
import { MENU }                     from 'constants'
import { useTranslation }           from 'react-i18next'
import { _project }                 from "state/store.projects";
import { active_project, vis_desktop_nav }           from "state/store.global"; 
import { getFocus, useGet }         from "state/jotai";
import { PoweredBy } from 'components/PoweredBy/PoweredBy'
import { getProjectData } from 'state/jotai.projectDataHooks'
import { useWindowSize } from 'hooks/useWindowResize'


export const Foldout = ({nav,show,setShow}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(true)
  const [menuHeight, setMenuHeight]       = useState(null)

  const window       = useWindowSize();
  const { pin, pid } = useGet(active_project);
  const menus        = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];
  const downloads    = getProjectData('downloads',0,[])
 
  const { i18n:{language} } = useTranslation()
  const buttonsLabel        = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
// console.log(menus,"mennn")
  const menuRef = useRef()
  const menu    = {
    initial   : { x:+MENU.DESKTOP_NAV_DRAWER_W+'px' },
    animate   : { x:300+'px' },
    exit      : { x:+MENU.DESKTOP_NAV_DRAWER_W+'px' },
    transition: { duration:0.3, ease:'linear' },
  }
  const animation = {
    initial   : { opacity: 0 },
    animate   : { opacity: 1 },
    exit      : { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  const dynamicFilter = (item, downloadsLength) => {
    // Exclude items with Order "10" regardless of downloads length
    if (item.Order === "10") return false;
    
    // Exclude items with Order "9" if downloads length is 0
    if (downloadsLength === 0 && item.Order === "9") return false;
    
    // Include all other items
    return true;
  };
  
  const menusItemsFiltered = menus.filter((item) => dynamicFilter(item, downloads.length));
  
 
  const menusItems         = menusItemsFiltered.map( (items, index) => {
  const item = {};
  for (const key in items) {
    if (items.hasOwnProperty(key)) {
      item[key.toLowerCase()] = items[key];
    }
  }
  return item; 
  })
  
  useEffect(() => {
    const bottomPadding   = 96
    const footerBarHeight = 72
    const height          = menuHeight || menuRef?.current?.clientHeight

    if(menuRef.current && ((height + bottomPadding + footerBarHeight) > window.height)) {
      !menuHeight && setMenuHeight(menuRef.current.clientHeight)
      isMenuVisible && setIsMenuVisible(false)
    }else if(menuHeight < window.height) {
      !isMenuVisible && setIsMenuVisible(true)
    }
  }, [window])

  return (
    <AnimatePresence>
      { show && <Menu 
          ref = {menuRef}
          as  = {motion.div}
          clr = {clr} {...animation}
        >
          <div style     = {{ color: clr?.primary_text , padding: "16px", borderBottom: `1px solid ${clr.primary_text}`, display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
          <div className = 'h5' style = {{width:"100%", display:"flex", justifyContent:"center"}}>
            {
              buttonsLabel?.filter(
                (btn) => btn?.UILabelProperty === "Hvorvildugernehen"
              )[0]?.Label
            }
            </div>
            <span className='icon-hover-effect '>
            <Icon icon = 'close'isFaIcon = {true} click = {() => setShow(false)} size = "xl" color = {clr?.primary_text}/>
            </span>
          </div>

          <div style = {{padding:"32px 24px 0px 24px", height: isMenuVisible ? "auto" : "calc(100vh - 255px)", overflow: 'auto'}}>
          { 
            menusItems.map(({url,text,icon},j) => {
              // console.log(icon,"dkdk")
              const ico = {href:url[language], icon, size: "25", color:clr?.primary_text,}
              return ( 
                <div  key               = {text} onClick           = {() => setShow(false)}  >
                <Link clr               = {clr} to                 = {url} key = {text} style = {{textDecoration:'none'}} >
                <Tile clr               = {clr}>
                <span className='icon-hover-effect' style={{display:"flex",gap:"8px"}}>
                <Icon {...ico} isFaIcon = {false} FaIconColor      = {clr.primary_text}/>
                <Text className         = 'paragraph1-light' clr = {clr}>{ text }</Text>
                </span>
                    </Tile>
                  </Link>
                  <Split clr = {clr}/>
                </div>
              )
            })
          }
          </div>
          <PoweredBy clr = {clr.primary_text} padding={"8px 0 16px 0"}/>
        </Menu>
      }
    </AnimatePresence>
  )
}
