import styled, {css} from 'styled-components'

export const Button = ({children,click,mode=1,fontSize='16px', borderRadius ="6px" , fontWeight=""}) => {
  return (
    <StyledButton clr={clr} onClick={click} mode={mode} fontSize={fontSize} fontWeight={fontWeight} borderRadius={borderRadius} className='paragraph1-regular'>
      { children }
    </StyledButton>
  )
}

const StyledButton = styled.button(
  ({ mode,fontSize,fontWeight, borderRadius }) => css`
  display : inline-flex;
  align-items : center;
  justify-content : center;
  position : relative;
  box-sizing : border-box;
    background-color: ${mode ? clr?.buttons : "#fff"};
    color: ${ mode ? clr?.buttons_text : "#000" };
    padding: 10px 20px;
    cursor: pointer;
    border: ${mode ? '0px solid black' : `1px solid #D1D1D1`};
    text-align: center;
    
    font-weight:${fontWeight};
    font-size:${fontSize};
    width: fit-content;
    white-space:nowrap;
    border-radius: ${borderRadius}
  `
)