import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { BREAK_ } from "constants";

import styled from "styled-components";
import { getFocus, useGet } from "state/jotai";
import { _project } from "state/store.projects";
import { active_project } from "state/store.global";

export const Tooltip = ({ showTooltip }) => {
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  return (
    <>
      <AnimatePresence>
        {showTooltip && (
          <Main as={motion.div} {...animation} clr={clr} className="label-regular">
            {buttonsLabel?.filter(btn => btn?.UILabelProperty === 'DrejBygningen')[0]?.Label}
          </Main>
        )}
      </AnimatePresence>
    </>
  );
};

export const Main = styled(motion.div)`
  display: flex;
  // width: 135px;
  height: 39px;
  border-radius: 8px;
  border: 1px solid ${({ clr }) => `${clr?.primary}bf`};
  background: ${({ clr }) => `${clr?.primary}bf`};
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  position: fixed;
  bottom: 54px;
  right: 47px;
  ${BREAK_.md_dn} {
    bottom: 80px;
    right: 35px;
  }
`;
