import { useSwiper } from 'swiper/react'
import styled from 'styled-components/macro'
import { ReactComponent as ArrowLeft } from './arrow-left.svg'
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import { getFocus, useGet } from 'state/jotai'
import { active_project } from 'state/store.global'
import { _project } from 'state/store.projects'
import { useLocation } from 'react-router-dom'
import { Icon } from 'assets/Icons_'

export const SwiperNav = ({ activeIndex, images }) => {

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const swiperHook = useSwiper()
  const _onClickPrev = () => { swiperHook.slidePrev() }
  const _onClickNext = () => { swiperHook.slideNext() }
  const here = useLocation().pathname;
  const atGallery = here.includes("gallery");

  return (
    <>
      <Nav>
        {
          ![0, null].includes(activeIndex) ?
            (<Btn onClick={_onClickPrev}><Icon color='black' fontAswStyle={{ fontSize: '16px' }} icon="chevronLeft" isFaIcon={true} /></Btn>) :
            (<Btn style={{ visibility: 'hidden' }}><Icon fontAswStyle={{ fontSize: '16px' }} color='black' icon="chevronLeft" isFaIcon={true} /></Btn>)
        }
        <Pag>{`${activeIndex + 1} ${buttonsLabel?.filter(btn => btn.UILabelProperty === 'udAf')[0]?.Label} ${images.length}`}</Pag>
        {
          activeIndex !== images.length - 1 ?
            (<Btn onClick={_onClickNext}><Icon fontAswStyle={{ fontSize: '16px' }} color='black' icon="chevronRight" isFaIcon={true} /></Btn>) :
            (<Btn style={{ visibility: 'hidden' }}><Icon fontAswStyle={{ fontSize: '16px' }} color='black' icon="chevronRight" isFaIcon={true} /></Btn>)
        }
      </Nav>
    </>
  )
}


const Left = styled(ArrowLeft)`
fill:white;
width:16px;
// margin-right:15px;
`

const Right = styled(ArrowRight)`
fill:white;
width:16px;
// margin-left:15px;
`

const Pag = styled.div`
  width:150px;
  padding:10px;
  display:flex;
  justify-content:center;
  color:white;
  gap:5px;
  user-select:none;
  font-size:0.9375rem;
  /* border:1px solid white; */
`
const Nav = styled.div`
  position:fixed;
  bottom:-50px;
  left:50%;
  transform:translatex(-50%);
  display:flex;
  padding:10px 0 5px 0;
  gap:10px;
  z-index:1;
  border-radius:19px 19px 0 0;
  background: transparent;
  `
const Btn = styled.button`
  border:0;
  user-select:none;
  background: #ffffffbf;
  padding:10px 10px;
  display:flex;
  height : 32px;
  width : 32px;
  justify-content:center;
  align-items:center;
  border-radius:6px;
  cursor:pointer;
  color:black;
`