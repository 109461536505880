import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Box, Button, Typography } from "@mui/material";
import { Icon } from "components/Icons";
import { useBreak } from "hooks/useBreak";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import styled from "styled-components";

function Description({ header, content, overlayColor, color = '#000' }) {
  const isMobile = useBreak("md_dn");
  const [isExpand, setIsExpand] = useState(false);
  const [ref, { height }] = useMeasure();

  const maxHeight = isMobile ? 300 : 344;
  const expandable = maxHeight < height;

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  

  const style = useSpring({
    // marginBottom: !isExpand ? "24px" : 0,
    height: isExpand ? height : maxHeight,
    overflow: "hidden",
    config: { duration: 200 },
  });

  const handleToggle = () => {
    setIsExpand((v) => !v);
  };

  return (
    <Box
      sx={{
        // position: "relative",
        fontSize: "16px",
        pb: expandable ? "42px" : 0,
        paddingRight: { xs: 0, lg: '46px' }
      }}
      color={color}
    >
      
      <animated.div style={{...style,  maskImage: !isExpand && expandable && "linear-gradient(to top, transparent, black 13%)", marginBottom: isExpand ? isMobile ? "12px" : "24px" : 0, }}>
        <Box ref={ref}>
          <Typography
            variant="h3"
            sx={{ mb: "16px", pt: 5, px: 5 }}
            className="h3"
          >
            {header}
          </Typography>
          <DescriptionText style={{ padding: "0 40px"}} color={color} className="paragraph1-regular" 
          dangerouslySetInnerHTML={{ __html: content }}
          >
          </DescriptionText>
        </Box>
      </animated.div>
      {expandable && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "130px"
          }}
        >
          {!isExpand && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                // background: `linear-gradient(0deg, ${overlayColor}8a 0%,  ${overlayColor}59 60%, ${overlayColor}00 100%)`,
                // background: `linear-gradient(0deg, ${overlayColor}8a 0%,  ${overlayColor}59 70%, ${overlayColor}59 76%, ${overlayColor}00 96% ,${overlayColor}00 100%)`
              }}
            ></Box>
          )}
          <Button
            onClick={handleToggle}
            startIcon={isExpand ? <Icon
             icon="unitPageDescription-arrowUp" isFaIcon={false} sx={{ width: "35px", height: "24px"}} />
              : <Icon icon="unitPageDescription-arrowDown" isFaIcon={false} sx={{ width: "40px", height: "24px"}} />}
            color="inherit"
            sx={{
              fontSize: 16,
              ".MuiButton-startIcon": {
                margin: isExpand ? 0 : "0 8px 0 -4px",
              },
              ".MuiButton-startIcon > svg": {
                fontSize: 30,
              },
              marginBottom: isExpand ? isMobile ? 1 : 2 : isMobile ? 2 : 4
            }}
          >
          </Button>
        </Box>
      )}
    </Box>
  );
}

Description.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  overlayColor: PropTypes.string,
};

Description.defaultProps = {
  header: "",
  content: "",
  overlayColor: "#ffffff",
};


const DescriptionText = styled.div`
  position: relative;
  p {
    color: ${({color}) => color} !important;
    font-family: Cerebri sans !important;
  }
  span {
    color: ${({color}) => color} !important;
    font-family: Cerebri sans !important;
  }
  // :after {
  // position: absolute;
  // content: '';
  // bottom: 0;
  // left: 0;
  // width: 100%;
  // height: 30px;
  // background: red;
  // }
`

export default Description;
