import styled from 'styled-components/macro'
import { BREAK } from 'constants'

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* border:5px solid red; */
`
export const Main = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 168px;
  border-left: 8px solid ${({ color }) => color};
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
  align-items: center;
  background-color: ${({ color }) => (() => color + '22')()};
  :hover {
    background-color: ${({ color }) => (() => color + '33')()};
  }
`